<script setup>
import { ref, defineAsyncComponent } from 'vue'

const expatTimesheetSummaryPeriod = defineAsyncComponent(() =>
  import('@/components/report/ExpatTimesheetSummaryPeriod.vue')
)

const expatTimesheetSummaryRangePeriod = defineAsyncComponent(() =>
  import('@/components/report/ExpatTimesheetSummaryRangePeriod.vue')
)

const nationalTimesheetSummaryPeriod = defineAsyncComponent(() =>
  import('@/components/report/NationalTimesheetSummaryPeriod.vue')
)

const nationalTimesheetSummaryRangePeriod = defineAsyncComponent(() =>
  import('@/components/report/NationalTimesheetSummaryRangePeriod.vue')
)

const payrollSummary = defineAsyncComponent(() =>
  import('@/components/report/PayrollSummary.vue')
)

const otCalculation = defineAsyncComponent(() =>
  import('@/components/report/OTCalculation.vue')
)

let laporan = ref('expatTimesheetSummaryPeriod')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Expat Timesheet Summary Period',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'expatTimesheetSummaryPeriod'
    },
  },
  {
    label: 'Expat Timesheet Summary Range Period',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'expatTimesheetSummaryRangePeriod'
    },
  },
  {
    label: 'National Timesheet Summary Period',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'nationalTimesheetSummaryPeriod'
    },
  },
  {
    label: 'National Timesheet Summary Range Period',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'nationalTimesheetSummaryRangePeriod'
    },
  },
  {
    label: 'Payroll Summary',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'payrollSummary'
    },
  },
  {
    label: 'OT Calculation',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'otCalculation'
    },
  },
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Report</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Report</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <expatTimesheetSummaryPeriod
            v-if="laporan === 'expatTimesheetSummaryPeriod'"
            @toggle="onToggle"
          />
          <expatTimesheetSummaryRangePeriod
            v-if="laporan === 'expatTimesheetSummaryRangePeriod'"
            @toggle="onToggle"
          />
          <nationalTimesheetSummaryPeriod
            v-if="laporan === 'nationalTimesheetSummaryPeriod'"
            @toggle="onToggle"
          />
          <nationalTimesheetSummaryRangePeriod
            v-if="laporan === 'nationalTimesheetSummaryRangePeriod'"
            @toggle="onToggle"
          />
          <payrollSummary
            v-if="laporan === 'payrollSummary'"
            @toggle="onToggle"
          />
          <otCalculation
            v-if="laporan === 'otCalculation'"
            @toggle="onToggle"
          />
        </div>
      </div>
    </div>
  </div>
</template>
